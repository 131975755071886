import React, { createRef, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  LoadingOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CenteredDiv } from "../courses/styled";
import {
  removeNewFromLesson,
  setCurrentVideo,
  togglePlayerRendering,
  updateLessonWatchTime,
} from "../../actions/lessons";
import API_ENDPOINTS from "../../api/apiEndpoints";
import { axiosAuth } from "../../api";
import {
  toggleRefundConfirmationModal,
  toggleStarRatingModal,
} from "../../actions/common";
import TelegramButton from "../../components/Telegram/TelegramButton";
import YoutubeButton from "../../components/YoutubeButton";
import CouponButton from "../../components/CouponButton";
import StarRatingModal from "../../components/StarRatingModal";

const LessonPlayer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [namePosition, setNamePosition] = useState({ x: 0, y: 0 });
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [isVideoEndedState, setVideoEnded] = useState(false);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [isPlayerBlocked, setIsPlayerBlocked] = useState(false);
  const [count, setCount] = useState(null);
  const currentLesson = useSelector((state) => state.lessons.currentLesson);
  const allLessons = useSelector((state) => state.lessons.allLessons);
  const showPlayer = useSelector((state) => state.lessons.showPlayer);
  const user = useSelector((state) => state.common.user);
  const showRatingModal = useSelector((state) => state.common.showRatingModal);
  const userCourseData = user?.purchasedCourses?.length
    ? user?.purchasedCourses.find((x) => x.slug === params.slug)
    : null;
  let interval = null;
  let countFinal = null;
  const playerRef = createRef();

  useEffect(() => {
    const isRatingGiven = user?.ratings?.some(
      (x) => x.courseSlug === params.slug
    );
    setPlayerReady(false);
    setVideoEnded(false);
    setVideoPlaying(false);
    setCount(null);
    countFinal = null;
    dispatch(togglePlayerRendering(false));
    if (
      currentLesson?.is_locked === "yes" &&
      userCourseData?.isRefundPeriodOver === false
    ) {
      dispatch(toggleRefundConfirmationModal(true));
      setIsPlayerBlocked(true);
    } else if (currentLesson?.showRating === true && !isRatingGiven) {
      dispatch(toggleStarRatingModal(true));
      setIsPlayerBlocked(true);
    } else {
      setIsPlayerBlocked(false);
    }
  }, [currentLesson, userCourseData]);

  useEffect(() => {
    // This is required to reset the player
    if (!showPlayer) {
      dispatch(togglePlayerRendering(true));
    }
  }, [showPlayer]);

  useEffect(() => {
    if (isPlayerReady) {
      playerRef.current.seekTo(parseFloat(currentLesson?.watchTime));
      if (currentLesson?.watchTime !== currentLesson?.duration_sec) {
        setVideoPlaying(true);
      }
    }
  }, [isPlayerReady, setVideoPlaying]);

  const handleActionClick = (video, sectionIndex, lessonIndex) => {
    if (video && currentLesson?._id !== video?._id) {
      setVideoPlaying(false);
      dispatch(setCurrentVideo(video));
      axiosAuth.post(API_ENDPOINTS.UPDATE_WATCH_TIME, {
        courseSlug: params.slug,
        isCompleted: video?.isCompleted || false,
        lessonId: video?._id,
      });
      if (video?.isNewLesson) {
        dispatch(
          removeNewFromLesson(sectionIndex, lessonIndex, {
            ...video,
            isNewLesson: false,
          })
        );
      }
    }
  };

  const actionVideos = useMemo(() => {
    let nextVideoToPlay = "";
    let prevVideo = "";
    let nextVideoSectionIndex = "";
    let nextVideoLessonIndex = "";
    let prevVideoSectionIndex = "";
    let prevVideoLessonIndex = "";
    const sectionIndex = allLessons.findIndex(
      (x) => x._id === currentLesson?.section?._id
    );
    const sectionLessons = allLessons[sectionIndex]?.lessons ?? [];
    const currentLessonIndex = sectionLessons?.findIndex(
      (x) => x._id === currentLesson?._id
    );
    if (sectionLessons[currentLessonIndex + 1]) {
      nextVideoToPlay = sectionLessons[currentLessonIndex + 1];
      nextVideoSectionIndex = sectionIndex;
      nextVideoLessonIndex = currentLessonIndex + 1;
    } else if (
      allLessons[sectionIndex + 1] &&
      allLessons[sectionIndex + 1]?.lessons[0]
    ) {
      nextVideoToPlay = allLessons[sectionIndex + 1]?.lessons[0];
      nextVideoSectionIndex = sectionIndex + 1;
      nextVideoLessonIndex = 0;
    }
    if (currentLessonIndex - 1 > -1 && sectionLessons[currentLessonIndex - 1]) {
      prevVideo = sectionLessons[currentLessonIndex - 1];
      prevVideoSectionIndex = sectionIndex;
      prevVideoLessonIndex = currentLessonIndex - 1;
    } else if (
      sectionIndex - 1 > -1 &&
      allLessons[sectionIndex - 1] &&
      allLessons[sectionIndex - 1]?.lessons?.length &&
      allLessons[sectionIndex - 1]?.lessons[
        allLessons[sectionIndex - 1]?.lessons?.length - 1
      ]
    ) {
      prevVideo =
        allLessons[sectionIndex - 1]?.lessons[
          allLessons[sectionIndex - 1]?.lessons?.length - 1
        ];
      prevVideoSectionIndex = sectionIndex - 1;
      prevVideoLessonIndex = allLessons[sectionIndex - 1]?.lessons?.length - 1;
    }

    return {
      nextVideoToPlay,
      prevVideo,
      prevVideoSectionIndex,
      prevVideoLessonIndex,
      nextVideoSectionIndex,
      nextVideoLessonIndex,
    };
  }, [allLessons, currentLesson]);

  const handleProgress = (data, isVideoEnded) => {
    if (isVideoEndedState) {
      return;
    }
    if (data?.playedSeconds === 0 && data?.played === 0) {
      return;
    }
    let payload = {
      ...currentLesson,
      watchTime: data.playedSeconds,
      isNewLesson: false,
    };
    if (isVideoEnded) {
      setVideoEnded(true);
      payload = { ...payload, isCompleted: true };
      if (actionVideos?.nextVideoToPlay) {
        interval = setInterval(() => {
          setCount(countFinal ? countFinal - 1 : 3);
          countFinal = countFinal ? countFinal - 1 : 3;
        }, 1000);
        setTimeout(() => {
          handleActionClick(
            actionVideos?.nextVideoToPlay,
            actionVideos?.nextVideoSectionIndex,
            actionVideos?.nextVideoLessonIndex
          );
          clearInterval(interval);
          interval = null;
        }, 4000);
      }
    }
    dispatch(updateLessonWatchTime(payload));
    axiosAuth.post(API_ENDPOINTS.UPDATE_WATCH_TIME, {
      courseSlug: params.slug,
      isCompleted: isVideoEnded ? true : currentLesson?.isCompleted === true,
      lessonId: currentLesson?._id,
      watchTime: isVideoEnded
        ? currentLesson?.duration_sec
        : data.playedSeconds > currentLesson?.duration_sec
        ? currentLesson?.duration_sec
        : data.playedSeconds,
    });
  };

  return (
    <LessonContainer>
      {showRatingModal && (
        <StarRatingModal hanldeAfterClose={() => setIsPlayerBlocked(false)} />
      )}
      <VideoTitle>{currentLesson?.post_title ?? ""}</VideoTitle>
      {!isPlayerReady && (
        <CenteredDiv>
          <LoadingOutlined style={{ fontSize: 30, color: "#f9ae00" }} spin />
        </CenteredDiv>
      )}
      <div>Sumit Khatri</div>
      <PlayerConatiner isHidden={!isPlayerReady}>
        <div style={{ position: "relative" }}>
          {showPlayer && !isPlayerBlocked && (
            <ReactPlayer
              ref={playerRef}
              url={currentLesson?.lesson_video}
              onReady={() => setPlayerReady(true)}
              controls
              onProgress={handleProgress}
              progressInterval={30000}
              playing={isVideoPlaying}
              onPlay={() => setVideoPlaying(true)}
              onPause={(data) => {
                handleProgress({ playedSeconds: data.seconds });
                setVideoPlaying(false);
              }}
              onSeek={(time) => handleProgress({ playedSeconds: time })}
              onEnded={() => {
                handleProgress(
                  { playedSeconds: currentLesson?.duration_sec },
                  true
                );
                setVideoPlaying(false);
              }}
              config={{
                file: {
                  attributes: {
                    crossorigin: "anonymous",
                  },
                },
              }}
            />
          )}
        </div>

        {/* <div
              style={{
                position: "absolute",
                top: `${namePosition.y}px`,
                left: `${namePosition.x}px`,
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                zIndex: 1000, // Ensure it's above the video player
              }}
            >
              Sumit Khatri
            </div> */}
      </PlayerConatiner>
      {isPlayerReady && !isPlayerBlocked && (count || count === 0) && (
        <Container>
          <NextVideoTimer>{`Next video starts in ${count}`}</NextVideoTimer>
        </Container>
      )}
      {isPlayerReady && !isPlayerBlocked && (
        <Container>
          <ActionButtonContainer>
            <Button
              style={{
                visibility: actionVideos?.prevVideo ? "visible" : "hidden",
                display: "flex",
                alignItems: "center",
              }}
              className="signUpBtn"
              onClick={() =>
                handleActionClick(
                  actionVideos?.prevVideo,
                  actionVideos?.prevVideoSectionIndex,
                  actionVideos?.prevVideoLessonIndex
                )
              }
            >
              <StepBackwardOutlined />
              Prev
            </Button>
            <Button
              style={{
                visibility: actionVideos?.nextVideoToPlay
                  ? "visible"
                  : "hidden",
                display: "flex",
                alignItems: "center",
              }}
              className="signUpBtn"
              onClick={() =>
                handleActionClick(
                  actionVideos?.nextVideoToPlay,
                  actionVideos?.nextVideoSectionIndex,
                  actionVideos?.nextVideoLessonIndex
                )
              }
            >
              Next
              <StepForwardOutlined />
            </Button>
          </ActionButtonContainer>
        </Container>
      )}
      {currentLesson?.showTelegram && (
        <TelegramButton
          customStyle={{ padding: "15px 0 25px 0", backgroundColor: "black" }}
        />
      )}
      {currentLesson?.showYoutubeSubscribe && (
        <YoutubeButton
          customStyle={{ padding: "15px 0 25px 0", backgroundColor: "black" }}
        />
      )}
      {currentLesson?.showCouponCode && (
        <CouponButton
          customStyle={{ padding: "15px 0 25px 0", backgroundColor: "black" }}
        />
      )}
    </LessonContainer>
  );
};

export default LessonPlayer;

const VideoTitle = styled.h4`
  text-align: center;
  margin-top: 25px;
  color: white;
`;

const LessonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  height: calc(100vh - 7%);
`;

const PlayerConatiner = styled.div`
  display: flex;
  justify-content: center;
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
  margin: 10px 0;
  position: relative;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const NextVideoTimer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
