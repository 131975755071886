import { Container } from 'react-bootstrap';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ColoredContainer, SectionHeadingText } from './Common';
import { theme } from '../../utils/theme';
import scamImage2 from '../../assets/scam2.png';
import ActionButton from './ActionButton';
import { ActionButtonContainer } from './BannerSection';
import { CheckboxContainer } from '../LandingPage/Checklist';
import {
  BigText,
  ImageContainer,
  LargeRedText,
  LargeText,
  LossWhiteText,
  MainDiv,
  SecondDiv,
} from '../Counter';
import { Spacer } from '../../routes/about';
import chart from '../../assets/piechart.png';
import finger from '../../assets/finger.png';
import { ButtonText } from './WhatYouGet';

const Scam = () => (
  <GrayColoredContainer>
    <Container>
      <div style={{ padding: '20px 0' }}>
        <Row justify="center">
          <Col>
            <MainHeading>Don&apos;t Get Scammed By People Who</MainHeading>
            {/* <SubHeadingYellowText>
              Profit & Loss Statement Is The Only Proof Of Authenticity
            </SubHeadingYellowText> */}
          </Col>
        </Row>
        <ContentContainer>
          <ContentBorderDiv>
            {/* <Text>Show Screenshot of Big Profits</Text> */}
            <CheckListContainer>
              <CheckboxContainerStyled>
                <WhiteText>Show Screenshot of Big Profits</WhiteText>
              </CheckboxContainerStyled>
              <CheckboxContainerStyled>
                <WhiteText>Promise unrealistic accuracy & returns</WhiteText>
              </CheckboxContainerStyled>
            </CheckListContainer>
            <CheckListContainer>
              <CheckboxContainerStyled>
                <WhiteText>
                  Talk about how many people they have trained
                </WhiteText>
              </CheckboxContainerStyled>
              <CheckboxContainerStyled>
                <WhiteText>Share selective testimonials </WhiteText>
              </CheckboxContainerStyled>
            </CheckListContainer>
          </ContentBorderDiv>
        </ContentContainer>
      </div>

      <LossWhiteText>
        But
        {' '}
        <span style={{ color: theme.colors.main }}>NEVER</span>
        {' '}
        Share Their
        Long-Term Profit & Loss Statement
      </LossWhiteText>
    </Container>
    <Spacer />
    <Spacer />
    <BlueContainer>
      <Container>
        <Row>
          <LossWhiteText>
            There’s Only 1 Reason To Hide Stock Market Performance
          </LossWhiteText>
        </Row>
        <Row justify="center">
          <img alt="one-reason" width="60px" src={finger} />
        </Row>
        <Row justify="center">
          <LargeRedText>LOSSES!</LargeRedText>
        </Row>
        <Spacer />
        <Spacer />
        <Row>
          <Col xs={24} sm={9} md={9} lg={9}>
            <MainDiv>
              <BlueText>
                Over a
                {' '}
                <BigText>3 year period</BigText>
                <br />
                <LargeText>ONLY 1%</LargeText>
                {' '}
                of traders
                <br />
                make more money than a bank
                {' '}
                <br />
                <BigText>Fixed Deposit</BigText>
              </BlueText>
            </MainDiv>
          </Col>
          <Col xs={24} sm={6} md={6} lg={6}>
            <ImageContainer>
              <img alt="chart" src={chart} width="200px" />
            </ImageContainer>
          </Col>
          <Col xs={24} sm={9} md={9} lg={9}>
            <SecondDiv>
              <BlueText>
                You
                {' '}
                <BigText>can&apos;t learn</BigText>
                <br />
                to make money in the markets from
                <br />
                <BigText>people who</BigText>
                &nbsp;
                <LargeRedText>LOSE MONEY</LargeRedText>
              </BlueText>
            </SecondDiv>
          </Col>
        </Row>

        <ButtonText>
          That&apos;s why I&apos;ll log into my Zerodha account & show you live
          that I&apos;ve made over &#8377;81 Lacs in 4 years after starting with
          &lt;&#8377;2 Lacs
        </ButtonText>
        <SpacedActionButtonContainer>
          <ActionButton />
        </SpacedActionButtonContainer>
      </Container>
    </BlueContainer>
  </GrayColoredContainer>
);

export default Scam;

const BlueContainer = styled(ColoredContainer)`
  padding: 10px 0;
  background-image: linear-gradient(to right, #141e30, #243b55);
  min-height: inherit;
`;

const GrayColoredContainer = styled(ColoredContainer)`
  background-image: linear-gradient(to right, #141e30, #243b55);
  min-height: inherit;
`;

const MainHeading = styled(SectionHeadingText)`
  margin-bottom: 20px;
  color: white;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;

const BlankSection = styled.div`
  width: 20px;
  min-height: 10px;
`;

const Text = styled.p`
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  color: black;
`;

const StyledImg = styled.img`
  height: 350px;
`;

export const SubHeadingWhiteText = styled.p`
  font-size: 20px;
  color: white;
  text-align: center;
  font-weight: bold;
`;

const SubHeadingYellowText = styled(SubHeadingWhiteText)`
  color: ${theme.colors.main};
  padding: 0 60px;
`;

const ContentBorderDiv = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    align-self: center;
  }
`;

export const SpacedActionButtonContainer = styled(ActionButtonContainer)`
  margin-bottom: 30px;
`;

const WhiteText = styled.p`
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
`;

const CheckListContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const CheckboxContainerStyled = styled(CheckboxContainer)`
  align-items: center;
  background: ${theme.colors.main}
`;

const BlueText = styled.p`
  color: white;
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

// box-shadow: 1px 1px 7px -2px #000000;
