import VideoPlayer from "./HoverPlayer";
import React, { useState } from "react";
import { useEffect } from "react";
import { axiosAuth } from "../../api";
import videoImg from "../../assets/video.png";

const Card = ({ item, isSelected, handleVideoClick }) => {
  return (
    <div
      onClick={handleVideoClick}
      className={`card ${isSelected ? "selected" : ""}`}
    >
      <img src={videoImg} alt={item.name} className="card-image" />
      <div className="card-content">
        <h3>{item.name}</h3>
        <p>{item.duration}</p>
      </div>
    </div>
  );
};

const VideoList = () => {
  const [selectLecture, setSelectedLecture] = useState(null);
  const [allLectures, setAllLectures] = useState([]);

  const getAllLectures = async () => {
    const res = await axiosAuth.get("/learning/live/lecture/list");
    if (res?.data?.data?.length) {
      setAllLectures(res?.data?.data);
    }
  };

  useEffect(() => {
    getAllLectures();
  }, []);

  const handleVideoClick = (item) => {
    setSelectedLecture(item);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <VideoPlayer selectLecture={selectLecture} />
      <hr />
      <div className="lecture-text">{selectLecture ? "" : "Please select lecture"}</div>
      <hr />
      <div className="grid-container">
        {allLectures.map((item) => (
          <Card
            key={item._id}
            item={item}
            isSelected={selectLecture?._id === item?._id}
            handleVideoClick={() => handleVideoClick(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default VideoList;
