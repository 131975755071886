import { Button } from "antd";
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.scss";

const VideoPlayer = ({ selectLecture }) => {
  const targetNodeRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  const handleFullscreenChange = (data, e) => {
    const isFullScreenEnabled = !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
    setIsFullScreen(isFullScreenEnabled);
    setPosition({ top: 0, left: 0 });
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      ); // Safari
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      ); // Firefox
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      ); // IE/Edge
    };
  }, []);

  useEffect(() => {
    const moveDiv = () => {
      const container = containerRef.current;
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const divSize = 100; // Size of the small div

      const maxX = containerWidth - divSize;
      const maxY = containerHeight - divSize;

      const randomX = Math.floor(Math.random() * maxX);
      const randomY = Math.floor(Math.random() * maxY);

      setPosition({ top: randomY, left: randomX });
    };

    const intervalId = setInterval(moveDiv, 10000); // Move every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    // Function to refresh the page
    const refreshPage = () => {
      window.location.reload();
    };

    // Set up the Mutation Observer
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        // Check if a specific element is removed
        mutation.removedNodes.forEach((removedNode) => {
          if (removedNode.id === "new-vid") {
            // Replace 'specific-element' with the ID or class of the element you want to monitor
            console.log("Specific element removed, refreshing the page...");
            refreshPage();
          }
        });
      }
    });

    // Observe the document body or a specific parent container
    const targetNode = document.body; // Or a more specific container
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    targetNodeRef.current = document.getElementById("new-vid");

    if (targetNodeRef.current) {
      // Function to capture the current inline styles
      const getInlineStyles = (element) => {
        const styles = element.style;
        return {
          top: styles.top,
          left: styles.left,
          visibility: styles.visibility,
          display: styles.display,
          height: styles.height,
          width: styles.width,
          opacity: styles.opacity,
          backgroundColor: styles.backgroundColor,
          color: styles.color,
        };
      };

      // Create a callback function to execute when mutations are observed
      const callback = function (mutationsList) {
        for (let mutation of mutationsList) {
          console.log("Mutation:", mutation);

          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            const currentStyles = getInlineStyles(targetNodeRef.current);
            console.log("Current styles:", currentStyles);

            // Check if styles include properties other than 'top' and 'left'
            if (
              Object.keys(currentStyles).some(
                (prop) =>
                  prop !== "top" && prop !== "left" && currentStyles[prop]
              )
            ) {
              // Refresh the page if any other style property is present
              window.location.reload();
              return; // Exit after triggering the refresh
            }
          } else if (
            mutation.type === "childList" ||
            mutation.type === "characterData"
          ) {
            // Refresh the page for child list changes or character data changes
            window.location.reload();
            return; // Exit after triggering the refresh
          }
        }
      };

      // Create a MutationObserver instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Specify what types of mutations to observe
      observer.observe(targetNodeRef.current, {
        attributes: true,
        attributeOldValue: true,
        childList: true,
        subtree: true,
        characterData: true,
      });

      // Cleanup observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    }
  }, []);

    const [isTransitioning, setIsTransitioning] = useState(true); // Start the transition immediately
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const toggleFullscreen = () => {
    if (!isFullScreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        // Firefox
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        // IE/Edge
        containerRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
    setPosition({ top: 0, left: 0 });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        ref={containerRef}
        style={{
          position: "relative",
          width: isFullScreen ? "inherit" : "45%",
          marginTop: "40px",
        }}
        className={`player-container ${isFullScreen ? "fullscreen" : ""}`}
      >
        <ReactPlayer
          ref={videoRef}
          url={selectLecture?.link ?? ""}
          controls
          config={{
            file: {
              attributes: {
                crossorigin: "anonymous",
              },
            },
            vimeo: {
              playerOptions: {
                controls: true,
                dnt: true, // Do Not Track
                title: false,
                byline: false,
                portrait: false,
                pip: false, // Disable Picture-in-Picture
                download: false, // Disable the download button
                fullscreen: false,
                autoplay: 1,
              },
            },
          }}
        />
        <Button
          onClick={toggleFullscreen}
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          {isFullScreen ? "Exit Full Screen" : "Full Screen"}
        </Button>
        {isTransitioning && (
          <div
            className="transition-css"
            id="new-vid"
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
          >
            {user?.name}
            <br />
            {user?.contactNo}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
